import { render, staticRenderFns } from "./HowPaymentsWork.vue?vue&type=template&id=11345630&scoped=true&"
import script from "./HowPaymentsWork.vue?vue&type=script&lang=js&"
export * from "./HowPaymentsWork.vue?vue&type=script&lang=js&"
import style0 from "./HowPaymentsWork.vue?vue&type=style&index=0&id=11345630&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11345630",
  null
  
)

export default component.exports